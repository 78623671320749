import { createContext } from "react";
import { IODataMetadata } from "../../../models";
import { ISelectedConnection } from "../../initialSetup/Setup/InitialSetupContext";

const metadata: IODataMetadata | null = {
  dataServices: {
    schema: {
      namespace: "",
      entityTypes: [],
      enumTypes: [],
      complexTypes: [],
      functions: [],
      actions: [],
      entityContainer: undefined,
    },
  },
};

export interface IODataContextType {
  selectedConnection: ISelectedConnection | { name: string; type: string };
  alreadyExistingTables: IODataTableRow[];
  availableTables: any[];
  failedTables: any[];
  metadata: IODataMetadata | null;
  schemas: any[];
}

export interface IODataTableRow {
  fullName: string;
  name: string | JSX.Element;
  connection?: JSX.Element;
  editButton?: JSX.Element;
}

export const ODataContext = createContext<IODataContextType>({
  selectedConnection: {
    name: "",
    type: "",
  },
  alreadyExistingTables: [],
  availableTables: [],
  failedTables: [],
  metadata: metadata,
  schemas: [],
});
